#site-footer {
  color: $white;
  background-color: $secondary-color;
  padding-top: 3rem;
  h4 {
    color: #fece0c;
  }
  .list-group-item {
    background-color: transparent;
    padding-left: 0;
    a {
      color: $white;
    }
  }
}

.social-links a {
  font-size: 2rem;
  padding: 5px;
}

.social-links a:hover {
  color: $secondary-color !important;
  background-color: $white;
  transition: all 0.3s;
  border-radius: 2px;
}

.copyright {
  padding-top: 1rem;
  margin-top: 30px;
  background-color: $secondary-color;
  border-top: 1px solid #a03537;
  color: white;
  a {
    color: #fece0c;
  }
}

#gotop {
  background-color: #fece0c;
  display: none;
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  border-radius: 2px;
  color: $white;
  text-decoration: none;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  span {
    font-size: 28px;
  }

}

a#gotop:hover {
  background-color: $white;
  color: $secondary-color;
  transition: all 0.3s;
}
