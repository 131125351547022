#primary-nav{
  background-color: $white;
  -webkit-box-shadow: 0 0 10px rgba(40,40,40,0.10);
  -moz-box-shadow: 0 0 10px rgba(40,40,40,0.10);
  -o-box-shadow: 0 0 10px rgba(40,40,40,0.10);
  box-shadow: 0 0 10px rgba(40,40,40,0.10);
}
.navbar-brand{
  margin: 0;
  display: flex;
  align-items: center;
}
.navbar-brand img{
  max-height: 40px;
}
.navbar-light .navbar-toggler{
  margin: 2px 0;
  border:none;
  }
.navbar-light .navbar-toggler span{
  color:$primary-color;
  padding: 2px 0;
}
.navbar-light .navbar-nav .nav-link{
  color: $automatic;
  text-transform:uppercase;
  font-weight: 500;
  padding-left:1rem;
  padding-right:1rem;
  display: inline-block;
}

.navbar-dark .navbar-nav .nav-link{
  color: $white;
  text-transform:uppercase;
  font-weight: 400;
  padding-left:1rem;
  padding-right:1rem;
  display: inline-block;
}

.navbar-light .navbar-nav .nav-link:hover{
  //background-color:$accent-color;
  //border-radius: .10rem;
  color:$secondary-color;
  transition: background-color 0.1s linear;
}
#primary-nav ul.navbar-nav li:last-child .nav-link{
  //background-color: $accent-color;
  //color: $primary-color;
  //border-radius: 2px;
  //border:1px solid $primary-color;
}
#primary-nav ul.navbar-nav li:last-child .nav-link:hover{
  //background-color: $primary-color;
  //color:$white;
}