.quick-enquiry{
  padding: 15px;
  border:1px solid rgba(33, 37, 41, 0.125);
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  .form-group{
    margin-bottom: 0;
  }
  .enquiry-heading{
    background-color: gray("600");
    color: $white;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 300;
    padding: 8px;
    text-align: center;
  }
  label{
    padding: 0 5px;
    margin: 15px 0 0 0;
    font-size: 14px;
  }
}
.quick-enquiry.featured-form{
  position: absolute;
  right: 100px;
  width: 320px;
  background-color: rgba(0,0,0,0.2);
  z-index: 3;
}