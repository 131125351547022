@import "animate";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
	1. 	Document Setup
	2. 	Element Base
	3. 	Links
	4. 	Site Header
	5. 	Navigation
	6. 	Form
	7. 	Page Templates
	    a.  Frontpage
		a. 	Template: Cover Template
		c. 	Template: Full Width
	8.  Post: Archive
	9.  Post: Single
	10. Blocks
	11. Entry Content
	12. Comments
	13. Site Pagination
	14. Error 404
	15. Widgets
	16. Site Footer
	17. Media Queries
--------------------------------------------------------------*/

/* -------------------------------------------------------------------------- */

/*	1. Document Setup
/* -------------------------------------------------------------------------- */
@import "document_setup";

/* -------------------------------------------------------------------------- */

/*	2. Element Base
/* -------------------------------------------------------------------------- */
@import "element_base";

/* -------------------------------------------------------------------------- */

/*	3. Links
/* -------------------------------------------------------------------------- */
@import "links";

/* -------------------------------------------------------------------------- */

/*	4.	Site Header
/* -------------------------------------------------------------------------- */
@import "header";

/* -------------------------------------------------------------------------- */

/*	5.	Navigation
/* -------------------------------------------------------------------------- */
@import "navigation";

/* -------------------------------------------------------------------------- */

/*	6.	Form
/* -------------------------------------------------------------------------- */
@import "form";

/* -------------------------------------------------------------------------- */

/*	7.	Page
/* -------------------------------------------------------------------------- */
@import "frontpage";

/* -------------------------------------------------------------------------- */

/*	8. Posts
/* -------------------------------------------------------------------------- */
@import "posts";

/* -------------------------------------------------------------------------- */
/*	16.	Site Header
/* -------------------------------------------------------------------------- */
@import "footer";
