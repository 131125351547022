html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
::selection{
  background-color: $secondary-color;
  color: $white;
}
body {
  background-color: $body-bg;
  color: $text;
}
h1,h2,h3,h4,h5,h6{
  font-weight: 600 !important;
}
p {
}
